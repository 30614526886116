import React from "react"
import SEO from "../components/seo"
import Header from "../components/headers/pricing/Header";
import Navigator from "../components/navigator/DefaultNavbar";
import Footer from "../components/footer/DefaultFooter";
import Pricing from "../components/pricing/Pricing";
import IncludedInEssentials from "../components/includedInEssentials/Essentials";
import IncludedInEnterprise from "../components/includedInEnterprise/Enterprise";
import CookieConsent from "../components/cookieConsent/CookieConsent"

const IndexPage = () => (
  <>
    <SEO
      title="Pricing details"
      description="Get the power, control and customization you need to take your app to the next level." />
    <Navigator
      useSpaNav={true}
    />
    <Header
      big={true}
      title="Pricing details"
      subtitle="Get the power, control and customization you need to take your app to the next level."
    />
    <Pricing />
    <div className="mt-5">
      <IncludedInEssentials />
    </div>
    <IncludedInEnterprise />
    <div className="mt-5">
      <Footer />
    </div>
    <CookieConsent />
  </>
)

export default IndexPage
