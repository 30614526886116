import React from "react";
import Container from "../container/Container";
import Title from "../typography/Title"
import Subtitle from "../typography/Subtitle"
import Description from "../typography/Description"
import "./styles.css";

const cards = [
  {
    title: 'Data models',
    items: [
      'Build entities',
      'Create complex relations',
      'Add custom or built-in validations',
    ],
    icon: 'customizable',
  },
  {
    title: 'Routers',
    items: [
      'REST endpoints',
      'Create advanced auth rules',
    ],
    icon: 'eye',
  },
  {
    title: 'Environments',
    items: [
      'Create docker containers',
      'Production-ready environments',
      'Integrated CI/CD tools',
    ],
    icon: 'maintanable',
  },
  {
    title: 'Data Mocking',
    items: [
      'Generate production like data',
      'Development ready environments',
      'Stress testing',
    ],
    icon: 'user',
  },
  {
    title: 'Deployment',
    items: [
      'Use built-in docker containers',
      'Deploy your API using serverless',
    ],
    icon: 'cloud',
  },
  {
    title: 'Source code',
    items: [
      'Always have access to the code',
      'Use JavaScript or TypeScript',
      'Compare and review the changes',
    ],
    icon: 'controllable',
  },
  {
    title: 'Internationalization',
    items: [
      'Translate error messages',
      'Create flows based on language',
    ],
    icon: 'i18n',
  },
  {
    title: 'Flows',
    items: [
      'View and update API flows',
      'Apply security rules',
      'Protect sensitive data',
    ],
    icon: 'flow',
  },
  {
    title: 'Security',
    items: [
      'Enable built-in security plugins',
      'Integrate AWS Cognito',
      'Setup Basic Auth instances',
    ],
    icon: 'lock',
  },
];

const AboutUs = () => {
  return (
  	<>
      <div className="essentials-section">
        <Container size="large">
          <div className="title-wrapper">
            <Title
              size="large"
              align="text-center"
            >
              What's included into the package
            </Title>
            <Subtitle
              align="text-center"
              size="large"
            >
              Key features included into the "Community" package which are easily customizable in our low-code studio
            </Subtitle>
          </div>
          <div className="content">
            {
              cards.map(({title, items, icon}) => (
                <div className="group">
                  <div className="description">
                    <div className="illustration">
                      <img src={require(`./images/${icon}.svg`)} alt={icon} width="48px" height="48px" />
                    </div>

                    <div className="text">
                      <Title
                        size="xsmall"
                      >
                        {title}
                      </Title>
                      {items && items.map((text) => (
                        <Description
                          size="small"
                        >
                          <img src={require(`./images/ok.svg`)} alt="ok" width="16px" height="16px" className="small-icon" />
                          {text}
                        </Description>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </Container>
      </div>
	  </>
  );
};

export default AboutUs;
