import React from "react";
import Container from "../container/Container";
import Title from "../typography/Title"
import Subtitle from "../typography/Subtitle"
import Description from "../typography/Description"
import "./styles.css";

const cards = [
  {
    title: 'Consulting',
    description: 'Get access to experts to find the best setup for your project and to optimize all your costs.',
    icon: 'user',
  },
  {
    title: 'Custom development',
    description: 'Get a complete project based on your requirements.',
    icon: 'development',
  },
  {
    title: 'Mockless cloud',
    description: 'Host your API without having to manage servers. Use the one-click deployment feature to put your API live.',
    icon: 'cloud',
  },
  {
    title: 'Unit tests',
    description: 'Creating and managing unit tests is time-consuming. Let Mockless automatically create those for you.',
    icon: 'unit',
  },
  {
    title: 'Automation tests',
    description: 'Automatically create E2E tests based on the flows defined on your API configuration.',
    icon: 'automation',
  },
  {
    title: 'Self customizations',
    description: 'Push code from your local IDE and let Mockless build on top',
    icon: 'self',
  },
  {
    title: 'CMS',
    description: 'Manage the API data directly into the Mockless studio.',
    icon: 'cms',
  },
  {
    title: 'GraphQL',
    description: 'Consume your data using GraphQL.',
    icon: 'eye',
  },
  {
    title: 'Premium support',
    description: '24/7 support.',
    icon: 'support',
  },
];

const AboutUs = () => {
  return (
  	<>
      <div className="enterprise-section">
        <Container size="large">
          <div className="title-wrapper">
            <Title
              size="large"
              align="text-center"
            >
              Premium features
            </Title>
            <Subtitle
              align="text-center"
              size="large"
            >
              Premium features to scale your product
            </Subtitle>
          </div>
          <div className="content">
            {
              cards.map(({title, description, icon}) => (
                <div className="group">
                  <div className="description">
                    <div className="illustration">
                      <img src={require(`./images/${icon}.svg`)} alt={icon} width="48px" height="48px" />
                    </div>

                    <div className="text">
                      <Title
                        size="xsmall"
                      >
                        {title}
                      </Title>
                      <Description
                        size="small"
                      >
                        {description}
                      </Description>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </Container>
      </div>
	  </>
  );
};

export default AboutUs;
