import * as React from 'react';
import "./styles.css";
import Container from "../container/Container"
import Title from "../typography/Title";
import Subtitle from "../typography/Subtitle";
import Description from "../typography/Description";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

export default () => {
  return (
    <div className="pricing-section">
      <Container size="xsmall">
        <div className="pricing-component">
          <div className="pricing-warning">
            <FontAwesomeIcon
                icon={["fas", "info-circle"]}
                className="icon"
            />
            Special prices available only during Beta Phase limited to 1000 customers.
          </div>
          <div className="pricing-wrapper">
            <div className="price-component">
              <div className="price-header">
                <Title size="small">
                  Community
                </Title>
                <Subtitle size="small">
                  Limited-time access to build your project and get the source code.
                </Subtitle>
              </div>

              <hr />

              <div className="content">
                <div className="price">
                  <Title size="large" lifetime={true}>
                    <del className="price-del">$500</del>$250
                  </Title>
                  <Description>
                    One time payment <br />
										45 days access to Mockless Studio <br />
										Life-time ownership of the source code
                  </Description>
                </div>
              </div>

              <div className="description">
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Unlimited resources
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Essentials features
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Self-hosting
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Community support
                  </div>
                </div>
              </div>
              <a href={`${process.env.REACT_APP_DASHBOARD_URL}/register`}>
                <div className="actions">
                  Start for FREE
                  <FontAwesomeIcon
                      icon={["fas", "long-arrow-alt-right"]}
                      className="animated-icon"
                  />
                  <span className="actions-info">Pay only when you get the source code!</span>
                </div>
              </a>
            </div>

            <div className="price-component">
              <div className="price-header">
                <Title size="small">
                  Startup
                </Title>
                <Subtitle size="small">
                  Collaborative tools to continuously develop your project.
                </Subtitle>
              </div>

              <hr />

              <div className="content">
                <div className="price">
                  <Title size="large">
                    <del className="price-del">$250</del>$125
                  </Title>
                  <Description>
                    Billed annually <br />
                    Life-time ownership of the source code
                  </Description>
                </div>
              </div>

              <div className="description">
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Everything from Community
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Unlimited developers
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Custom code integration
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Monthly updates
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Standard support
                  </div>
                </div>
              </div>
							<a href={`${process.env.REACT_APP_DASHBOARD_URL}/register`}>
                <div className="actions">
                  Start for FREE
                  <FontAwesomeIcon
                      icon={["fas", "long-arrow-alt-right"]}
                      className="animated-icon"
                  />
									<span className="actions-info">Pay only when you get the source code!</span>
                </div>
              </a>
            </div>

            <div className="price-component">
              <div className="price-header">
                <Title size="small">
                  Corporate
                </Title>
                <Subtitle size="small">
                  Give us the requirement, we will deliver the whole project.
                </Subtitle>
              </div>

              <hr />

              <div className="content">
                <Title size="large">
                  Contact Us
                </Title>
              </div>

              <div className="description margin">
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Everything from Startup
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Premium features
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Premium consulting
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Mockless cloud access
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={require(`./images/ok.svg`)} alt="ok" width="24px" height="24px" />
                  </div>
                  <div className="text">
                    Premium support
                  </div>
                </div>
              </div>

              <Link to={ "/contact" }>
                <div className="actions primary">
                  Contact sales
                  <FontAwesomeIcon
                      icon={["fas", "long-arrow-alt-right"]}
                      className="animated-icon"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
